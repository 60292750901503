import { connect } from 'react-redux';
import Fair from './Fair';
import getColumns from './getColumns';
import React from 'react';
import { toggleFilterable } from '../../../actions/table';
import {
    fetchFairPoints,
    fetchFairProducts,
    fetchFairSuppliers,
    fetchFairDynamicWeeksData,
    addFairDynamicWeekPromiseCreator,
} from '../../../actions/routines';
import * as qs from 'qs';
import history from '../../../history';
import FairService from '../../../services/api/Fair';
import { setFairProducts } from '../../../actions/fair';
import FairDynamicWeeksModalContainer from '../../FairDynamicWeeksModal/FairDynamicWeeksModalContainer';
import { bindActionCreators } from 'redux';
import bindPromiseCreators from 'redux-saga-routines/dist/bindPromiseCreators';

class FairContainer extends React.Component {
    constructor(props) {
        super(props);

        const queryParams = qs.parse(history.location.search.slice(1));

        const columns = getColumns(false);

        this.state = {
            selectedSupplier:
                queryParams.s_s_supplier !== undefined
                    ? queryParams.s_s_supplier
                    : 'all-suppliers',
            selectedProductType:
                queryParams.mylist !== undefined ? queryParams.mylist : '1',
            fairCartProducts: [],
            isDynamicWeeksModalOpen: false,
            loadingState: {},
            selectedGroup: 0,
            columns: columns,
            whatColumnsToShowForFairProducts: localStorage.getItem(
                'whatColumnsToShowForFairProducts'
            )
                ? JSON.parse(
                      localStorage.getItem('whatColumnsToShowForFairProducts')
                  )
                : columns,
        };
    }

    componentDidMount() {
        const { userDetails } = this.props;

        const queryParams = qs.parse(history.location.search.slice(1));
        queryParams.mylist = 0; //Force to fetch all suppliers
        this.props.fetchFairSuppliers(queryParams);
        this.props.fetchFairPoints();
        this.props.fetchFairDynamicWeeksData();

        if (userDetails && !userDetails.is_fair_active) {
            history.push('/dashboard');
        }
        FairService.getFairProductsList().then((response) =>
            this.setState({ fairCartProducts: response.data })
        );
        this.props.toggleFilterable();

        const { columns, whatColumnsToShowForFairProducts } = this.state;
        const updatedColumns = columns.map((column) => {
            const matchedColumn = whatColumnsToShowForFairProducts.find(
                (c) => c.id === column.id
            );
            if (matchedColumn) {
                return { ...column, show: matchedColumn.show };
            }
            return column;
        });
        this.setState({ columns: updatedColumns });
    }

    componentDidUpdate(prevProps) {
        const { userDetails } = this.props;

        if (userDetails && !userDetails.is_fair_active) {
            history.push('/dashboard');
        }
    }

    addProduct = async (week, productId, amount) => {
        const loadStateKey = `${productId}-${week}`;

        this.setState((prevState) => ({
            loadingState: { ...prevState.loadingState, [loadStateKey]: true },
        }));

        await FairService.addProduct(
            week,
            productId,
            amount === '' ? 0 : amount
        );

        await FairService.getFairProductsList().then((response) =>
            this.setState({ fairCartProducts: response.data })
        );

        const queryParams = qs.parse(history.location.search.slice(1));

        queryParams.mylist = this.state.selectedProductType;

        history.push({
            ...this.props.location,
            search: qs.stringify(queryParams),
        });
        this.props.fetchFairProducts(queryParams);

        this.setState((prevState) => ({
            loadingState: { ...prevState.loadingState, [loadStateKey]: false },
        }));
    };

    fetchData = (params) => {
        params.mylist = this.state.selectedProductType;

        this.props.fetchFairProducts(params);
    };

    getSelectedSupplier = (e) => {
        if (this.state.selectedProductType === e[0]) {
            return;
        }

        const params = qs.parse(history.location.search.slice(1));
        params.s_s_supplier = e[0];
        params.page = '1';

        history.push({
            ...this.props.location,
            search: qs.stringify(params),
        });

        this.props.fetchFairProducts(params);
    };

    handleProductTypeSelect = (myList) => {
        if (this.state.selectedProductType === myList) {
            return;
        }
        this.setState({
            selectedProductType: myList,
        });

        const queryParams = qs.parse(history.location.search.slice(1));
        queryParams.mylist = myList;
        queryParams.page = '1';

        history.push({
            ...this.props.location,
            search: qs.stringify(queryParams),
        });

        this.props.fetchFairProducts(queryParams);
    };

    handleDynamicWeeksClick = () => {
        this.setState({
            isDynamicWeeksModalOpen: true,
        });
    };

    closeDynamicWeekModal = () => {
        this.setState({
            isDynamicWeeksModalOpen: false,
        });
    };

    addDynamicWeekHandler = (selectedDynamicWeek) => {
        const yearMonth = selectedDynamicWeek.split('-');

        this.props
            .addFairDynamicWeekPromiseCreator({
                week: yearMonth[1],
                year: yearMonth[0],
            })
            .then((response) => {
                const queryParams = qs.parse(history.location.search.slice(1));
                this.props.fetchFairProducts(queryParams);
                this.props.fetchFairDynamicWeeksData();
                this.closeDynamicWeekModal();
            });
    };

    onGroupFilter = (id) => {
        const queryParams = qs.parse(history.location.search.slice(1));
        queryParams.group_id = id;
        this.fetchData(queryParams);

        this.setState({ selectedGroup: id });
    };

    handleToggleColumn = (c) => {
        const columns = [];
        columns.push(...this.state.columns);
        columns.forEach((column, index) => {
            if (column.id === c.target.name) {
                columns[index].show = !column.show;
            }
        });
        const columnsObjectsWithBoolean = columns.map((el) => ({
            id: el.id,
            show: el.show,
        }));
        localStorage.setItem(
            'whatColumnsToShowForFairProducts',
            JSON.stringify(columnsObjectsWithBoolean)
        );
        this.setState({
            columns: columns,
            whatColumnsToShowForProducts: JSON.parse(
                localStorage.getItem('whatColumnsToShowForFairProducts')
            ),
        });
    };

    render() {
        const {
            filterable,
            total,
            isLoading,
            suppliers,
            fairPoints,
            data,
            dynamicWeeksData,
        } = this.props;

        return (
            <React.Fragment>
                <Fair
                    selectedGroup={this.state.selectedGroup}
                    onGroupFilter={this.onGroupFilter}
                    isFilterable={filterable}
                    fairCartProducts={this.state.fairCartProducts}
                    data={data}
                    columns={this.state.columns}
                    handleToggleColumn={this.handleToggleColumn}
                    selectedSupplier={this.state.selectedSupplier}
                    suppliers={suppliers}
                    handleSelectSupplier={this.getSelectedSupplier}
                    handleFetchData={this.fetchData}
                    setFairProducts={this.props.setFairProducts}
                    itemsCount={total}
                    isLoading={isLoading}
                    handleProductTypeSelect={this.handleProductTypeSelect}
                    fairPoints={fairPoints}
                    setFairCartProducts={this.setFairCartProducts}
                    addProduct={this.addProduct}
                    showDynamicWeeksButton={
                        dynamicWeeksData && dynamicWeeksData.totalMaxAmount > 0
                    }
                    disableDynamicWeeksButton={
                        dynamicWeeksData &&
                        dynamicWeeksData.totalMaxAmount <=
                            dynamicWeeksData.userWeeksTotal
                    }
                    handleDynamicWeeksClick={this.handleDynamicWeeksClick}
                    loadingState={this.state.loadingState}
                />
                <FairDynamicWeeksModalContainer
                    isOpen={this.state.isDynamicWeeksModalOpen}
                    addDynamicWeekHandler={this.addDynamicWeekHandler}
                    closeModal={this.closeDynamicWeekModal}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filterable: state.table.filterable,
        data: state.fairProducts.data,
        total: state.fairProducts.total,
        isLoading: state.fairProducts.loading,
        suppliers: state.fairSuppliers.data,
        userDetails: state.userDetails.data,
        fairPoints: {
            data: state.fairPoints.data,
            isLoading: state.fairPoints.loading,
        },
        dynamicWeeksData: state.fairDynamicWeeks.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                toggleFilterable,
                fetchFairProducts,
                fetchFairSuppliers,
                fetchFairPoints,
                fetchFairDynamicWeeksData,
                setFairProducts,
            },
            dispatch
        ),
        ...bindPromiseCreators(
            {
                addFairDynamicWeekPromiseCreator,
            },
            dispatch
        ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FairContainer);
