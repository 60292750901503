import React from 'react';
import { connect } from 'react-redux';
import { fetchCampaigns } from '../../actions/routines';
import CampaignSticker from './CampaignSticker';
import { FormattedMessage } from 'react-intl';

class CampaignStickers extends React.Component {
    componentDidMount() {
        this.props.fetchCampaigns();
    }

    render() {
        if (this.props.campaigns.length === 0) {
            return null;
        }

        return (
            <div>
                <div
                    className={`campaign__stickers-title ${
                        this.props.shape !== 'rectangle' ? 'alignRight' : ''
                    }`}
                >
                    <FormattedMessage id="campaign.filter_title" />
                </div>
                <div className="campaign-stickers">
                    <div
                        className={
                            this.props.shape !== 'rectangle'
                                ? 'campaign__stickers-container'
                                : ''
                        }
                    >
                        {this.props.campaigns.map((campaign) => {
                            return (
                                <CampaignSticker
                                    key={campaign.id}
                                    campaign={campaign}
                                    shape={this.props.shape}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        campaigns: state.campaigns.data,
    };
};

export default connect(mapStateToProps, { fetchCampaigns })(CampaignStickers);
