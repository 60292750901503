import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment/moment';

const Promotions = ({ promotions }) => (
    <React.Fragment>
        {promotions.map((promotion) => {
            const date =
                moment(promotion.start_date).format('DD/MM/YYYY') +
                ' - ' +
                moment(promotion.end_date).format('DD/MM/YYYY');
            return (
                <div
                    className={`product-details__promotion product-details__promotion--${
                        promotion.is_future
                            ? 'is-future'
                            : promotion.promotion_type
                    }`}
                    dangerouslySetInnerHTML={{
                        __html: `${promotion.promotion}<p></p><p></p><div>${date}</div>`,
                    }}
                />
            );
        })}
    </React.Fragment>
);

export default Promotions;

Promotions.propTypes = {
    promotions: PropTypes.array,
};
