import PropTypes from 'prop-types';
import React from 'react';
import { Container } from 'mdbreact';
import Table from '../../Table/Table';
import TitleHeader from '../../Pages/components/TitleHeader';
import icon from '../../../assets/images/exhibition-module-grey.svg';
import PageHeader from './components/PageHeader/PageHeader';
import TableHeader from './components/TableHeader/TableHeader';
import { FormattedMessage } from 'react-intl';
import FairSubcomponent from '../../Table/components/FairSubcomponent/FairSubcomponent';
import ProductGroupsContainer from './components/ProductGroups/ProductGroupsContainer';
import PreferencesPhoto from '../../Table/components/PreferencesPhoto';

class Fair extends React.Component {
    render() {
        const {
            isFilterable,
            data,
            columns,
            selectedSupplier,
            suppliers,
            handleSelectSupplier,
            handleFetchData,
            itemsCount,
            isLoading,
            handleProductTypeSelect,
            fairPoints,
            fairCartProducts,
            setFairProducts,
            addProduct,
            showDynamicWeeksButton,
            disableDynamicWeeksButton,
            handleDynamicWeeksClick,
            loadingState,
            selectedGroup,
            onGroupFilter,
            handleToggleColumn,
        } = this.props;
        return (
            <div className="cart">
                <Container className="px-0">
                    <TitleHeader title="fair.title" icon={icon}>
                        <Container>
                            <PageHeader
                                handleProductTypeSelect={
                                    handleProductTypeSelect
                                }
                                fairCartProducts={fairCartProducts}
                            />
                        </Container>
                    </TitleHeader>

                    <ProductGroupsContainer
                        selectedGroup={selectedGroup}
                        onGroupFilter={onGroupFilter}
                    />

                    <TableHeader
                        selectedSupplier={selectedSupplier}
                        suppliers={suppliers}
                        fairPoints={fairPoints}
                        handleSelectSupplier={handleSelectSupplier}
                        showDynamicWeeksButton={showDynamicWeeksButton}
                        disableDynamicWeeksButton={disableDynamicWeeksButton}
                        handleDynamicWeeksClick={handleDynamicWeeksClick}
                    />
                    <div className="font-small">
                        <FormattedMessage id="fair.legend" />
                    </div>
                    <PreferencesPhoto
                        columns={columns}
                        handleToggleColumn={handleToggleColumn}
                    />
                    <Table
                        filterable={isFilterable}
                        data={data}
                        isFairTable={true}
                        pageSize={data.length}
                        columns={columns}
                        defaultExpanded={data.map((x) => true)}
                        expanded={data.map((x) => true)}
                        SubComponent={(row) => {
                            return (
                                <FairSubcomponent
                                    row={row}
                                    fairProducts={data}
                                    setFairProducts={setFairProducts}
                                    addProduct={addProduct}
                                    pageSize={data.length}
                                    loadingState={loadingState}
                                />
                            );
                        }}
                        onFetchData={(state) => handleFetchData(state)}
                        manual
                        itemsCount={itemsCount}
                        loading={isLoading}
                        isFairFixed={true}
                    />
                </Container>
            </div>
        );
    }
}

Fair.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
};

export default Fair;
