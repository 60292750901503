import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    SelectOption,
} from 'mdbreact';
import { FormattedMessage, injectIntl } from 'react-intl';
import NestedOption from '../Pages/MasterTable/components/Search/NestedOption';
import ButtonWithSpinner from '../Utils/ButtonWithSpinner';
import history from '../../history';
import * as qs from 'qs';
import { allProducts, myProducts } from '../../assets/images/icons';
import KSicon from '../../assets/images/keurslager-icon.png';
import Souffrioicon from '../../assets/images/souffrio-icon.svg';
import ProductCounter from '../Table/components/ProductCounter';
import ProductTypeFilter from '../Table/components/Button/ProductTypeFilter';
import KeurslagerLabel from '../Table/components/Button/KeurslagerLabel';
import FiltersContainer from '../Pages/MasterTable/components/Filters/FiltersContainer';
import chevronSvg from '../../assets/images/chevron-icon.svg';
import { Chip } from 'mdbreact';
import { MultiSelect } from 'react-multi-select-component';

class SearchWithFiltersMA extends Component {
    constructor(props) {
        super(props);

        this.state = {
            wasInitialized: false,
            prevSelected: [],
        };
    }

    fixLabel = (node) => {
        if (node && this.props.withSelect) {
            const label = node.querySelector(
                '.select-wrapper ul.select-dropdown input + label'
            );

            if (!label) {
                return;
            }

            label.textContent = this.props.intl.formatMessage({
                id: 'search.select.input_placeholder',
            });

            label.onclick = () => {
                label.classList.add('active');
            };
        }
    };

    getProductTypeFilterIcon(selectedType) {
        switch (selectedType) {
            case 1:
                return myProducts;
            case 2:
                return KSicon;
            default:
                return allProducts;
        }
    }

    isChecked = (selectedValues, currentValue) => {
        if (!selectedValues) {
            return false;
        }
        return selectedValues.includes(currentValue);
    };

    arraysAreIdentical(arr1, arr2) {
        if (arr1.length !== arr2.length) {
            return false;
        }

        arr1.sort();
        arr2.sort();

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }

        return true;
    }

    getPromotionTypeFilterLabel(
        selectedType,
        countWithFilter,
        countWithoutFilter
    ) {
        switch (selectedType) {
            case 1:
                return this.props.intl.formatMessage(
                    { id: 'table.promotion_filter.with_promotion' },
                    { count: countWithFilter }
                );
            case 2:
                return this.props.intl.formatMessage(
                    { id: 'table.promotion_filter.without_promotion' },
                    { count: countWithoutFilter }
                );
            case 0:
            default:
                return this.props.intl.formatMessage({
                    id: 'table.promotion_filter.label',
                });
        }
    }

    render() {
        const {
            placeholder,
            productSelectedType,
            handleProductTypeSelect,
            suppliers,
            selectedSupplierValue,
            setSelectedSupplier,
            setCommonSearch,
            searchHandler,
            commonSearchValue,
            withoutFilterAllergens,
            deleteSelectedSupplier,
            intl,
            handlerSelectedFilters,
        } = this.props;

        let suppliersChecked;
        let options = [];

        if (suppliers?.length) {
            suppliersChecked = suppliers.map((supplier) => ({
                value: supplier.name,
                text: supplier.name,
                name: supplier.name,
                checked: this.isChecked(selectedSupplierValue, supplier.id),
            }));
            options = suppliers.map((supplier) => ({
                label: supplier.name,
                value: supplier.id,
            }));

            options.unshift({
                label: intl.formatMessage({
                    id: 'table.filters.input.supplier',
                }),
                value: 'all_suppliers',
            });

            options.sort((a, b) => {
                return Number(a.checked) - Number(b.checked);
            });
        }

        let productTypeOptions = [];

        if (handleProductTypeSelect) {
            productTypeOptions.push({
                label: `${intl.formatMessage({
                    id: 'table.ma_supplier.label',
                })}`,
                value: 4,
                icon: Souffrioicon,
                text: intl.formatMessage({
                    id: 'table.ma_supplier.label',
                }),
            });
        }

        return (
            <div className="main-search-filter-wrapper">
                <form id="main-search-filter" onSubmit={searchHandler}>
                    <div>
                        <div ref={this.fixLabel} className="search">
                            <input
                                onChange={setCommonSearch}
                                value={commonSearchValue}
                                type="text"
                                className="search__input search__input-whith-filters common-search-filter"
                                placeholder={placeholder}
                            />
                        </div>

                        <div>
                            {handleProductTypeSelect && (
                                <MultiSelect
                                    options={productTypeOptions}
                                    ItemRenderer={(item) => (
                                        <span
                                            onClick={() => {
                                                handleProductTypeSelect(
                                                    item?.option?.value
                                                );
                                            }}
                                        >
                                            {item?.option?.icon && (
                                                <img
                                                    src={item.option.icon ?? ''}
                                                    alt="icon"
                                                    className="keurslager-icon"
                                                />
                                            )}
                                            {item.option.label}
                                        </span>
                                    )}
                                    value={[
                                        {
                                            value: productSelectedType,
                                            label: this.props.intl.formatMessage(
                                                {
                                                    id: 'table.ma_supplier.label',
                                                }
                                            ),
                                        },
                                    ]}
                                    labelledBy="Select"
                                    className="dropdown-multi"
                                    hasSelectAll={false}
                                    disableSearch
                                    ClearSelectedIcon={() => <></>}
                                    overrideStrings={{
                                        allItemsAreSelected: intl.formatMessage(
                                            {
                                                id: 'table.ma_supplier.label',
                                            }
                                        ),
                                    }}
                                />
                            )}
                        </div>

                        {!withoutFilterAllergens && (
                            <div>
                                <FiltersContainer
                                    onlyFilters={['allergens']}
                                    handlerSelectedFilters={
                                        handlerSelectedFilters
                                    }
                                />
                            </div>
                        )}

                        {suppliersChecked && (
                            <div className={`supplier-wrapper`}>
                                <>
                                    <MultiSelect
                                        options={options}
                                        ItemRenderer={(item) => {
                                            return (
                                                <div
                                                    className="supplier-item"
                                                    onClick={(e) => {
                                                        e.preventDefault();

                                                        item.onClick();
                                                    }}
                                                >
                                                    <span>
                                                        {item.option.label}
                                                    </span>
                                                </div>
                                            );
                                        }}
                                        overrideStrings={{
                                            selectSomeItems: intl.formatMessage(
                                                {
                                                    id: 'table.filters.input.supplier',
                                                }
                                            ),
                                            search: intl.formatMessage({
                                                id: 'table.search.placeholder_lower',
                                            }),
                                        }}
                                        value={selectedSupplierValue}
                                        onChange={setSelectedSupplier}
                                        labelledBy="Select"
                                        className="dropdown-multi"
                                        hasSelectAll={false}
                                        id="select-suppliers"
                                        valueRenderer={() => (
                                            <span>
                                                {intl.formatMessage({
                                                    id: selectedSupplierValue.length
                                                        ? 'table.preferences.supplier'
                                                        : 'table.filters.input.supplier',
                                                })}
                                            </span>
                                        )}
                                    />
                                    {!!selectedSupplierValue?.length && (
                                        <div className={'suppliers_chips'}>
                                            {selectedSupplierValue?.map(
                                                (supplier) => {
                                                    return (
                                                        <Chip className="suppliers_chip">
                                                            <span>
                                                                {supplier.label}
                                                            </span>
                                                            <button
                                                                type="button"
                                                                data-id={
                                                                    supplier.value
                                                                }
                                                                data-type={
                                                                    supplier.value
                                                                }
                                                                data-name={
                                                                    supplier.label
                                                                }
                                                                className="filters__remove-filter chip_remove"
                                                                onClick={() =>
                                                                    deleteSelectedSupplier(
                                                                        supplier.value
                                                                    )
                                                                }
                                                            />
                                                        </Chip>
                                                    );
                                                }
                                            )}
                                        </div>
                                    )}
                                </>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        );
    }
}

SearchWithFiltersMA.propTypes = {
    placeholder: PropTypes.string.isRequired,
};

export default injectIntl(SearchWithFiltersMA);
