import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeDeliveryData } from '../../Utils/makeFakeData';
import * as qs from 'qs';
import history from '../../../history';
import Invoice from './Invoice';
import { fetchInvoices, fetchUserData } from '../../../actions/routines';

class InvoiceContainer extends React.Component {
    componentDidMount() {
        const queryParams = qs.parse(history.location.search.slice(1));

        this.props.fetchInvoices(queryParams);
    }

    render() {
        if (
            process.env.REACT_APP_ENABLE_INVOICE !== 'true' ||
            !this.props.showPrices
        ) {
            return '';
        }

        return <Invoice loading={this.props.loading} data={this.props.data} />;
    }
}

const mapStateToProps = (state) => {
    const customerDetails =
        state.userDetails.data &&
        state.userDetails.data?.current_customer?.customer_details
            ? state.userDetails.data?.current_customer?.customer_details
            : null;

    return {
        data: state.invoices.data,
        loading: state.invoices.loading,
        showPrices: customerDetails ? customerDetails.show_price : false,
    };
};

export default connect(mapStateToProps, { fetchInvoices, fetchUserData })(
    InvoiceContainer
);

InvoiceContainer.propTypes = {
    data: PropTypes.array,
    fetchInvoices: PropTypes.func,
    fetchUserData: PropTypes.func,
};

InvoiceContainer.defaultProps = {
    data: makeDeliveryData(5),
};
